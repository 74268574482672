export interface WebsiteConfig {
  title: string;
  description: string;
  homeDescription: string; /* for meta tags */
  coverImage?: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  /**
   * full url, no username
   */
  siteUrlShort: string;
  /**
   * full url, no username
   */
  facebook?: string;
  /**
   * full url, no username
   */
  linkedin?: string;
  /**
   * full url, no username
   */
  linkedinAuthor?: string;
  /**
   * full url, username
   */
  github?: string;
  /**
   * full url, no username
   */
  medium?: string;
  /**
   * full url, no username
   */
  twitter?: string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
  /**
   * name and id of the mailchimp email field
   */
  mailchimpEmailFieldName?: string;
  /**
  /**
   * Meta tag for Google Webmaster Tools
   */
  googleSiteVerification?: string;
  /**
  /**
   * Appears alongside the footer, after the credits
   */
  footer?: string;
  /**
   * Appears in the link in the footer text, after the credits
   */
  businessInfo: string;
}

const config: WebsiteConfig = {
  title: 'Innerdoc',
  homeDescription: 'Deep Text Search. Made Intelligent.',
  description: 'Deep Text Search. Made Intelligent. Innerdoc creates Natural Language Processing and Text Mining solutions for your documents and textual data.',
  coverImage: 'img/homepage-cover.jpg',
  logo: 'img/innerdoc-icon.png',
  lang: 'en',
  siteUrl: 'https://www.innerdoc.com',
  siteUrlShort: 'www.innerdoc.com',
  facebook: 'https://www.facebook.com/Innerdoc-323035795225757',
  linkedin: 'https://www.linkedin.com/company/innerdoc',
  linkedinAuthor: 'https://www.linkedin.com/in/robvanzoest/',
  twitter: 'https://twitter.com/innerdoc_nlp',
  github: 'https://github.com/innerdoc',
  medium: 'https://medium.com/innerdoc',
  showSubscribe: false,
  mailchimpAction: 'https://twitter.us19.list-manage.com/subscribe/post?u=a89b6987ac248c81b0b7f3a0f&amp;id=7d777b7d75',
  mailchimpName: 'b_a89b6987ac248c81b0b7f3a0f_7d777b7d75',
  mailchimpEmailFieldName: 'MERGE0',
  googleSiteVerification: 'UA-138295391-1',
  footer: 'Ready to help you show off with Text Analytics!',
  businessInfo: '/business-solutions',
};

export default config;
